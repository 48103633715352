import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import logo from "../assets/logo.jpeg"

const New2 = () => {

    const navigate = useNavigate();
    const [value,setValue] = useState("")


    const next=()=>{
      if (value === 1) {
        navigate("/new3");
      }else{
        navigate("/new44");
      }
    }
    const cancel=()=>{
        navigate("/new")
    }
  return (
    <form onSubmit={next} className='welcome'>
        <div className='nav'>
        <img  style={{width:"40px",height:"40px",borderRadius:"100px",backgroundColor:"blue"}} src={logo} alt="" />
      <p>New Wallet</p>
    </div>
      <div className="welcomeContainer">
        <div className="welcomeBox2">
            <p style={{color:"white"}}>What kind of wallet do you want to create?</p>
            <div className='radioContainer'>
            <input onChange={()=> setValue(1)} name='r' className='radio' type="radio" required/><p>Standard wallet</p>
            </div>
            <div className='radioContainer'>
            <input onChange={()=> setValue(2)} name='r' className='radio' type="radio" required/><p>Import Bitcoin addresses or private keys</p>
            </div>
        </div>
      </div>
      <div className="buttons">
        <button onClick={cancel} className='but1'>Cancel</button>
        <button className='but2' type='submit'>Next</button>
      </div>
    </form>
  )
}

export default New2
