import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from 'react-router-dom';
import Nav from './Components/Nav';
import Welcome from './Pages/Welcome';
import New from './Pages/New';
import New2 from './Pages/New2';
import New3 from './Pages/New3';
import New4 from './Pages/New4';
import New5 from './Pages/New5';
import { useState } from 'react';
import Account from './Pages/Account';
import New33 from './Pages/New33';
import New44 from './Pages/New44';

function App() {
  const [name,setName] = useState("");
  const [seed,setSeed] = useState("");
  const [ke,setKe] = useState("");

  return (
    <Router> 
<Routes> 
    <Route exact path='/' element={< Welcome />}></Route>  
    <Route path='/new' element={< New name={name} setName={setName} />}></Route>  
    <Route path='/new2' element={< New2 />}></Route> 
    <Route path='/new3' element={< New3 />}></Route>  
    <Route path='/new4' element={< New4 setSeed={setSeed} ke={ke}/>}></Route>  

    <Route path='/new44' element={< New44 ke={ke} setKe={setKe} />}></Route>  
    <Route path='/new33' element={< New33 ke={ke} setKe={setKe} />}></Route>  


    <Route path='/new5' element={< New5 name={name} seed={seed} ke={ke} setKe={setKe} setSeed={setSeed} />}></Route>  
    <Route path='/account' element={< Account name={name} />}></Route>  

 
</Routes>

</Router>
  );
}

export default App;
