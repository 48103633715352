import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import logo from "../assets/logo.jpeg"

const New33 =({ke,setKe}) => {

    const navigate = useNavigate();

    const next=()=>{
        navigate("/new5");
    }
    const cancel=()=>{
        navigate("/new3")
    }
    useEffect(()=>{
      console.log(ke)
    },[])
  return (
    <form onSubmit={next} className='welcome'>
        <div className='nav'>
        <img  style={{width:"40px",height:"40px",borderRadius:"100px",backgroundColor:"blue"}} src={logo} alt="" />
      <p>New Wallet</p>
    </div>
      <div className="welcomeContainer"> 
        <div className="welcomeBox3">
            <p style={{color:"white"}}>Private key Type</p>
            <select name="" id="">
                <option value="">Electrum</option>
            </select>
        </div>
      </div>
      <div style={{display:"none"}} className="welcomeContainer">
        <div className="annonce">
            <p>Electrum seeds are the default seed type. If you are restoring from a seed previously created by Electrum, choose this option</p>
        </div>
      </div>
      <div className="welcomeContainer">
      <textarea onChange={(e)=> setKe(e.target.value)} value={ke} placeholder=' Enter your master key' required></textarea>
      </div>
      <div className="buttons">
        <button onClick={cancel} className='but1'>Cancel</button>
        <button type='submit' className='but2'>Next</button>
      </div>
    </form>
  )
}

export default New33