import React from 'react'
import "./Nav.css"

const Nav = () => {
  return (
    <div className='nav'>
      <div style={{width:"40px",height:"40px",borderRadius:"100px",backgroundColor:"blue"}}></div>
      <p>Electrum Bitcoin Wallet</p>
    </div>
  )
}

export default Nav
