import React from 'react'
import img from '../assets/wallet.png'
import { useNavigate } from 'react-router-dom';
import logo from "../assets/logo.jpeg"

function Account({name}) {

    const navigate = useNavigate();

    const next=()=>{
    }
    const cancel=()=>{
    }

  return (
    <div className='welcome'>
      <div className='nav'>
      <div style={{width:"40px",height:"40px",borderRadius:"100px",display:"flex",justifyContent:"center",alignItems:"center"}}>
        <img style={{width:"20px"}} src={img} alt="" />
      </div>
      <p>{name}</p>
    </div>
    <center>
    <div className="dashboard">
        <div style={{marginBottom:"20px"}} className="header">
            <h3>Balance</h3>
            <p>0 mBtc</p>
        </div>
        <div className="header">
            <p>Lightning</p>
            <p>0 mBtc</p>
        </div>
        <div className="header">
            <p>On-chain</p>
            <p>0 mBtc</p>
        </div>
    </div>
    <center>
        <div className='an'>
            <h1>No transactions in this wallet yet</h1>
        </div>
    </center>
    </center>
    <div className="buttons2">
        <button onClick={cancel} className='but1'>RECEIVE</button>
        <button onClick={next} className='but2'>SEND</button>
      </div>
    </div>
  )
}

export default Account
 