import React from 'react'
import "./Welcome.css"
import { Link, useNavigate } from 'react-router-dom'
import logo from "../assets/logo.jpeg"

const Welcome = () => {

    const navigate = useNavigate();

    const next=()=>{
        navigate("/new")
    }
    const cancel=()=>{
        navigate("/")
    }
  return (
    <div className='welcome'>
        <div className='nav'>
          <img  style={{width:"40px",height:"40px",borderRadius:"100px",backgroundColor:"blue"}} src={logo} alt="" />
      <p>Electrum Bitcoin Wallet</p>
    </div>
      <div className="welcomeContainer">
        <div className="welcomeBox">
            <center>
            <img  style={{width:"150px",height:"150px",borderRadius:"100px",backgroundColor:"blue"}} src={logo} alt="" />
            </center>
        </div>
      </div>
      <div className="buttons">
        <button onClick={cancel} className='but1'>Cancel</button>
        <button onClick={next} className='but2'>Next</button>
      </div>
    </div>
  )
}

export default Welcome
