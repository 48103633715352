import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import logo from "../assets/logo.jpeg"

const New = ({name,setName}) => {

    const navigate = useNavigate();

    const next=()=>{
        navigate("/new2")
    }
    
    const cancel=()=>{
        navigate("/")
    }
  return (
    <form onSubmit={next} className='welcome'>
        <div className='nav'>
        <img  style={{width:"40px",height:"40px",borderRadius:"100px",backgroundColor:"blue"}} src={logo} alt="" />
      <p>New Wallet</p>
    </div>
      <div className="welcomeContainer">
        <div className="welcomeBox2">
            <p className='p'>Wallet name</p>
            <input onChange={(e)=> setName(e.target.value)} className='inputName' type="text" required/>
        </div>
      </div>
      <div className="buttons">
        <button onClick={cancel} className='but1'>Cancel</button>
        <button className='but2' type='submit'>Next</button>
      </div>
    </form>
  )
}

export default New
